<template lang="pug">
v-app
  v-content
    router-view(id="luxy-wrap")
</template>

<style lang="scss">
@import "./plugins/meltline.css";
@import "@/scss/_variables.scss";
@import "@/scss/common.scss";

html, body {
  margin: 0;
  font-family: $font_rohminsink;
  color: $primary_text_color;
}

.v-application {
  font-family: $font_rohminsink !important;
}

.v-application--wrap {
  min-height: -webkit-fill-available;
}

span, p, a, textarea, textarea::placeholder {
  // font-size: 14px;
  font-feature-settings: 'palt';
  letter-spacing: 0.04em
}

span {
  color: $primary_text_color;
}

// スクロールバーを全て削除
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display:none;
  }
}
</style>

<script>
export default {
  name: 'App',
  data () {
    return {
      isScrolling: false, scrollTop: 0,
    }
  },
  async mounted () {
    this.appHeight()
    window.addEventListener('resize', this.appHeight)

    // 慣性スクロールは取りやめ中です
    // await this.sleep(600)
    // this.initializeLuxy()
    // setTimeout(this.initializeLuxy, 600)
  },
  destroyed () {
    window.removeEventListener('resize', this.appHeight)
  },
  methods: {
    appHeight () {
      const doc = document.documentElement
      doc.style.setProperty('--vh', (window.innerHeight*.01) + 'px')
    },
    initializeLuxy () {
      // luxy.init({
      //   wrapper: '#luxy-wrap',
      //   targets : '.luxy-col',
      //   wrapperSpeed: 0.11
      //   // wrapperSpeed: 0.09
      // })
    },
    sleep (msec) {
      return new Promise(resolve => setTimeout(resolve, msec))
    }
  }
}
</script>
